<template>
  <div class="complete">
    <div class="title">
      <img src="./img/success.png" alt="" /><span>您的申请已经提交成功~</span>
    </div>
    <div class="image">
      <img src="./img/complete.png" alt="" />
    </div>
    <div class="btn white" @click="toRouter">返回首页</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activityId: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    // 去活动列表页面
    toRouter() {
      this.$router.replace({
        name: "elderlyCareAppointment",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.complete {
  box-sizing: border-box;
  min-height: 100%;
  background: #fafafaff;
  padding: 138px 48px 0px;
  margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      vertical-align: middle;
      overflow: hidden;
    }
    span {
      font-size: 48px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #7cc0a3;
      line-height: 66px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 2px;
    }
  }
  .image {
    width: 568px;
    height: 322px;
    margin: 126px auto 232px;
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      vertical-align: middle;
    }
  }
  .btn {
    width: 640px;
    height: 96px;
    margin: 0px auto 30px;
    font-size: 48px;
    line-height: 96px;
    text-align: center;
    font-weight: bold;
    border-radius: 16px;
    background: #7cc0a3;
    color: #ffffff;
    // &.blue {
    //   color: #ffffff;
    //   background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    // }
    // &.white {
    //   color: #3680ff;
    //   border: 2px solid rgba(110, 165, 255, 1);
    //   // border-image: linear-gradient(
    //   //     180deg,
    //   //     rgba(110, 165, 255, 1),
    //   //     rgba(49, 125, 255, 1)
    //   //   )
    //   //   2 2;
    // }
  }
}
</style>
